//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-272:_9640,_8929,_5724,_2036,_820,_9180,_44,_6544;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-272')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-272', "_9640,_8929,_5724,_2036,_820,_9180,_44,_6544");
        }
      }catch (ex) {
        console.error(ex);
      }